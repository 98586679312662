<template>
  <div class="animated fadeIn">
    <b-container>
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity"
        :background-color="this.$config.LOADER.backgroundColor"
        :z-index="10000"
      ></loading>
      <b-row class="mt-5">
        <b-col>
          <h4>No Dtl</h4>
          <b-card>
            <b-progress :max="accounts.length" show-progress animated>
              <b-progress-bar :value="index">
                {{ index }}/{{ accounts.length }}
              </b-progress-bar>
            </b-progress>
            <b-button
              block
              variant="primary"
              @click="start()"
              size="lg"
              v-if="!is_start"
              :disabled="is_run"
              >start</b-button
            >
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row>
            <b-col>
              <b-card title="Алдаа гарсан">
                {{ status_count[0] }}
              </b-card>
            </b-col>
            <b-col>
              <b-card title="Амжилттай">
                {{ status_count[1] }}
              </b-card>
            </b-col>
            <b-col>
              <b-card title="Дата нь олдоогүй">
                {{ status_count[2] }}
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "no-dtl",
  data: function() {
    return {
      isLoading: false,
      is_start: false,
      is_run: false,
      restart_list: [],
      accounts: [],
      value: 0,
      index: 0,
      status_count: [0, 0, 0, 0, 0, 0, 0],
    };
  },
  created() {
    this.load();
  },
  methods: {
    load: function() {
      this.isLoading = true;
      this.$http
        .get(this.$config.API_URL + "ReportWebService/no_dtl_list", {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.isLoading = false;
            //console.log(response)
            this.$data.accounts = response.body.data;
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    start: function() {
      this.$data.is_start = true;
      this.run();
    },
    run: function() {
      this.$http
        .get(this.$config.API_URL + "ReportWebService/no_dtl_one", {
          params: { id: this.$data.accounts[this.$data.index] },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.$data.status_count[response.data.status]++;
            //console.log(this.$data.status_count);
            this.$data.index++;
            if (this.$data.index < this.$data.accounts.length) {
              this.run();
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
  },
};
</script>
